<template>
  <div>
    <v-tabs-items v-model="type" class="transparent" touchless>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api
          :ref="'tablePlanning0'"
          :api="url"
          :model="[...modelClient]"
          :useQuery="false"
          :defaults="defaults0"
          :selectedRows="selectedRows"
          :headRows="2"
          @click="
            counter += 1;
            onDetectClick($event);
          "
          @dataUpdated="dataMdf()"
        />
      </v-tab-item>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api
          :ref="'tablePlanning'"
          :api="url"
          :model="[...model]"
          :useQuery="false"
          :defaults="defaults1"
          :selectedRows="selectedRows"
          :headRows="2"
          @click="
            counter += 1;
            onDetectClick($event);
          "
          @dataUpdated="dataMdf()"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import libObjectPlanning from "../planning/libObjectPlanning";
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, libObjectPlanning, genModel],
  components: {},
  props: {
    id: Number,
    data: Object,
    type: Number,
  },
  data() {
    return {
      idItemShow: 0,
      showReportDialog: false,
      clickRow: null,
      counter: 0,
      selectedRows: [],
      url: "/mechti/objects",
      progress: null,
      m: this.$store.getters["config/get"].models.objectPlanning,
      modelClient: [
        { name: "balanceCalc", type: "object", hidden: true },
        { name: "id", title: "#", type: "id", sortable: true, width: 50 },
        { name: "name", title: "Название", type: "string", style: "white-space: normal;", sortable: true, width: 200 },
        { name: "client_id", title: "Клиент", type: "select", dir: "client", style: "white-space: normal;", sortable: true, width: 200 },
        { name: "investment_amount", title: "Денег внесено", type: "number", calculated: true, formula: `((d) => {return d.balanceCalc?.type_2 || null;})` },
        { name: "expenses", title: "Расходы", type: "number", calculated: true, formula: `((d) => {return d.balanceCalc?.type_1 || null;})` },
        { name: "dept_expenses", title: "Неоплачено", type: "number", calculated: true, formula: `((d) => {return d.balanceCalc?.['type_-1'] || null;})` },
        { name: "money", title: "Средства компании", type: "number", formula: `((d) => {return d.balanceCalc?.type_90 || null;})` },
        { name: "money_inv_balance", title: "Остаток средств клиента", type: "number", formula: `((d) => {return d.balanceCalc?.type_20 || null;})` },
      ],
    };
  },
  created() {
    console.log(this.m);
  },
  watch: {},
  computed: {
    model() {
      let model = this.getModelList(this.m); //[...this.$store.getters["config/get"].models.objectPlanning.list];
      //let model = this.type == 1 ? [...this.$store.getters["config/get"].models.objectPlanning.list] : [...this.$store.getters["config/get"].models.objects.list];
      if (model.find(m => m.name == "percent_finish"))
        model.find(m => m.name == "percent_finish").formula = d => {
          if (!d.progress) return "???";
          let res = "";
          let total = 0;
          let done = 0;
          let p = { Покупки: 0, Дизайнер: 0, Стройка: 0, Мебель: 0 };
          d.progress.forEach(r => (p[r.name] = r.per));
          for (const [key, value] of Object.entries(p)) {
            res = res + (res ? " " : "") + key.substr(0, 1) + value.toFixed(0);
            total += 100;
            done += value;
          }
          res = ((100 * done) / (total || 1)).toFixed(2) + `% (${res})`;
          return res;
        };
      return model;
    },
    defaults0() {
      let where = {};
      let t = this.type;
      where = { is_investor: 0 };
      let res = {
        sort: { key: "id", order: "DESC" },
        filters: { status: [10, 90], is_investor: 0 },
        paramName: "objectsPlanning",
        where,
      };
      return res;
    },
    defaults1() {
      let where = {};
      let t = this.type;
      where = { is_investor: 1 };
      let res = {
        sort: { key: "id", order: "DESC" },
        filters: { status: [10, 90], is_investor: 1 },
        paramName: "objectsPlanning",
        where,
      };
      return res;
    },
  },
  inject: ["showPlanning"],
  methods: {
    onClickRow(d) {
      this.showPlanning(d.row.id);
    },

    onSingleClick(d) {
      if (this.$root.typeClick > 1) {
        this.clickRow = d.row.id;

        if (this.selectedRows.includes(d.row.id)) {
          this.selectedRows = [];
        } else {
          this.selectedRows = [d.row.id];
        }
      }
      if (this.$root.typeClick == 1) {
        this.onDblClick(d);
      }
      this.clickRow = 0;
    },
    onDblClick(d) {
      if (this.clickRow === d.row.id || this.$root.typeClick == 1) {
        this.showPlanning(d.row.id);
      }
      this.clickRow = 0;
    },
    onDetectClick(d) {
      if (this.counter == 1)
        this.click = setTimeout(() => {
          this.onSingleClick(d);
          this.click = undefined;
          this.counter = 0;
        }, 250);
      if (this.counter == 2) {
        this.counter = 0;
        clearTimeout(this.click);
        this.click = undefined;
        this.onDblClick(d);
      }
      this.clickRow = d.row.id;
    },
    async dataMdf() {
      //console.log("data upd", this.$refs.tablePlanning.data);
      //let data = [...this.$refs.tablePlanning.data];
      let name = "tablePlanning" + (this.type ? "" : 0);
      // let name = "tablePlanning";
      let data = this.$refs[name].data;
      if (!data) return;
      let progress = this.progress || (await this.getObjectProgress(0));
      if (!this.progress) {
        this.progress = progress;
      }
      for (let i = 0; i < data.length; i++) {
        let d = data[i];
        let object_balance = d?.balance_calc;
        if (object_balance?.calc) {
          //console.log("saved balance", object_balance);
        } else {
          if (!true) {
            console.log("нету баланса", d);
          } else {
            let balance = await this.getObjectBalance(d);
            await this.getDocumentPayments(d);
            let calc = await this.calcBalance(d, balance);
            object_balance = calc;
            //console.log("lets save calc", d, object_balance);
            this.balanceSave(d.id, object_balance.calc);
          }
        }
        this.$refs[name].data[i].balanceCalc = object_balance.calc;

        this.$refs[name].data[i].progress = progress.filter(p => {
          return p.object_id == d.id;
        });

        this.$refs[name].$forceUpdate();
      }
    },
    async balanceSave(id, b) {
      if (!id) return;
      try {
        let d = { id, balance_calc: { date: new Date().date, calc: b } };
        //console.log("post", d);
        let response = await this.$axios.post(this.url, d);
        if (response.data.status !== "ok") {
          console.log("error", response);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
